<template>
  <div class="grid">
    <Toast />
    <div class="col-12">
      <div v-if="responseData !== undefined" class="card">

        <div class="flex">
          <div class="m-2">
            <div class="p-float-label">
              <Dropdown v-model="selectedSemester" @change="filterData" inputId="slc-semester" :options="semesters"
                optionLabel="semester" class="w-full md:w-14rem" />
              <label for="slc-semester"><i class="pi pi-calendar" style="font-weight: 900;"></i> Buscar
                período</label>
            </div>
          </div>
          <div class="m-2">
            <button @click="reloadData" title="Recarregar" severity="secondary"
              class="p-button p-component p-button-icon-only p-button-secondary p-button-rounded p-button-outlined p-button-sm">
              <i class="pi pi-refresh" style="font-weight: 900;"></i>
            </button>
          </div>
        </div>

        <div style="margin-top: 2%;">
          <div v-if="responseData !== undefined">

            <DataTable :value="responseData" tableStyle="min-width: 50rem" expandableRowGroups
              v-model:expandedRowGroups="expandable" rowGroupMode="subheader" groupRowsBy="area" sortMode="single"
              sortField="area" :sortOrder="1">
              <template #groupheader="slotProps">
                <span class="vertical-align-middle ml-2 font-bold line-height-3">{{
                  slotProps.data.area }}</span>
              </template>
              <Column class="text-center" field="mail" header="Email"></Column>
              <Column field="timeFormatted" header="Horário" style="width: 20%"></Column>
              <Column field="status" header="Status" style="width: 20%">
                <template #body="{ data }">
                  <Tag :value="getStatusValue(data.data.status)" v-if="data.data.status != 'pending'"
                    v-tooltip.top="{ value: `Revisado por: ` + data.data.reviewerMail + `<br>Data: ` + localformatDate(data.data.reviewedTimestamp), class: 'edited-tooltip-managerFiles' }"
                    :severity="getSeverity(data.data.status)" />
                  <Tag :value="getStatusValue(data.data.status)" v-else :severity="getSeverity(data.data.status)" />
                </template>

              </Column>
              <Column :exportable="false" header="Ações" style="min-width: 8rem;">
                <template #body="slotProps">
                  <div v-if="slotProps.data.data.status == 'pending'">
                    <Button title="Baixar" icon="pi pi-download" class="p-button-rounded p-button-warning mr-2"
                      @click="downloadFile(slotProps.data)" />
                    <Button title="Recusar" icon="pi pi-times" class="p-button-rounded p-button-danger mr-2"
                      @click="refuseFile(slotProps.data)" />
                    <Button title="Aprovar" icon="pi pi-check" class="p-button-rounded p-button-success mr-2"
                      @click="approveFile(slotProps.data)" />
                  </div>
                  <div v-else>
                    <Button icon="pi pi-download" class="p-button-rounded p-button-warning mr-2"
                      @click="downloadFile(slotProps.data)" />
                    <Button icon="pi pi-times" class="p-button-rounded p-button-secondary mr-2" disabled />
                    <Button icon="pi pi-check" class="p-button-rounded p-button-secondary mr-2" disabled />
                  </div>
                </template>
              </Column>
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Util from "../service/Util";
import DROService from "../service/DROService";

export default {
  data() {
    return {
      selectedSemester: null,
      expandable: null,
      responseData: [],
      fetchFiles: null,
      currentSemester: null,
      semesters: [],
    };
  },
  watch: {

  },
  methods: {
    getSemesters() {

      const startingSemester = {
        semester: 1,
        year: 2022
      }

      let tempSemester = { ...this.currentSemester }

      const semester = [];

      while (tempSemester.year >= startingSemester.year && tempSemester.semester >= startingSemester.semester) {

        semester.push({
          semester: tempSemester.year + '.' + tempSemester.semester
        });
        tempSemester.semester--;
        if (tempSemester.semester == 0) {
          tempSemester.semester = 2;
          tempSemester.year--;
        }
      }
      return semester;
    },
    getCurrentSemester() {
      let currentDate = new Date()
      if (parseInt(currentDate.getMonth()) <= 5) {
        return {
          semester: 1,
          year: parseInt(currentDate.getFullYear())
        }
      }
      else {
        return {
          semester: 2,
          year: parseInt(currentDate.getFullYear())
        }
      }

    },
    reloadData() {
      this.responseData = []

      this.selectedSemester = {semester: this.currentSemester.year + "." + this.currentSemester.semester }

      Util.loading = true;
      DROService.fileList(this.currentSemester.semester, this.currentSemester.year).then(data => {
        data.forEach(file => {
          file.timeFormatted = Util.formatDate(file.data.sendTimestamp, true)
        })

        this.responseData = data
        Util.loading = false;
      })
    },
    filterData() {
      if (this.selectedSemester == null) {
        return
      }

      Util.loading = true;
      const [year, semester] = this.selectedSemester.semester.split(".");

      this.responseData = []
      DROService.fileList(semester, year).then(data => {
        data.forEach(file => {
          file.timeFormatted = Util.formatDate(file.data.sendTimestamp, true)
        })

        this.responseData = data
        Util.loading = false;
      })
    },
    approveFile(tableLine) {
      DROService.fileStatus('approved', tableLine.data.key).then(() => {
        if(this.selectedSemester != null){
          this.filterData()
        }
        else{
          this.reloadData()
        }
      })
    },
    refuseFile(tableLine) {
      DROService.fileStatus('refused', tableLine.data.key).then(() => {
        if(this.selectedSemester != null){
          this.filterData()
        }
        else{
          this.reloadData()
        }
      })
    },
    downloadFile(tableLine) {
      DROService.fileRead(tableLine.data.key).then(data => {
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(data);
        a.download = tableLine.data.key;
        a.click();
      })
    },
    getSeverity(status) {
      switch (status) {
        case 'refused':
          return 'danger';

        case 'approved':
          return 'success';

        case 'pending':
          return 'warning';
      }
    },
    getStatusValue(status) {
      switch (status) {
        case 'refused':
          return 'Recusado';

        case 'approved':
          return 'Aprovado';

        case 'pending':
          return 'Pendente';
      }
    },
    localformatDate(date) {
      return Util.formatDate(date, true)
    }
  },
  mounted() {
    this.currentSemester = this.getCurrentSemester()
    this.semesters = this.getSemesters()
    this.reloadData()
  },
  beforeUnmount() {
    clearInterval(this.fetchFiles);
  }
};
</script>

<style>
.edited-tooltip-managerFiles .p-tooltip-text {
  white-space: nowrap;
  width: fit-content;
}
</style>